
/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menus/meat': {
    width: 16,
    height: 16,
    viewBox: '0 0 141.7 141.7',
    data: '<g _fill="#2f2f2f"><path pid="0" d="M50 56.3c.4.4.8.5 1.2.5s.9-.2 1.2-.5c.7-.7.7-1.9 0-2.6-1.9-1.9-1.9-4.8 0-6.6 1.6-1.6 2.4-3.6 2.4-5.8 0-2.2-.9-4.3-2.4-5.8-.7-.7-1.9-.7-2.6 0-.7.7-.7 1.9 0 2.6.9.9 1.4 2 1.4 3.3s-.5 2.4-1.4 3.3c-3 3.1-3 8.3.2 11.6zM89.6 56.3c.4.4.8.5 1.2.5.4 0 .9-.2 1.2-.5.7-.7.7-1.9 0-2.6-1.9-1.9-1.9-4.8 0-6.6 1.6-1.6 2.4-3.6 2.4-5.8 0-2.2-.9-4.3-2.4-5.8-.7-.7-1.9-.7-2.6 0-.7.7-.7 1.9 0 2.6.9.9 1.4 2 1.4 3.3s-.5 2.4-1.4 3.3c-3 3.1-3 8.3.2 11.6zM69.8 45.5c.4.4.8.5 1.2.5.4 0 .9-.2 1.2-.5.7-.7.7-1.9 0-2.6-1.9-1.9-1.9-4.8 0-6.6 1.6-1.6 2.4-3.6 2.4-5.8s-.9-4.3-2.4-5.8c-.7-.7-1.9-.7-2.6 0s-.7 1.9 0 2.6c.9.9 1.4 2 1.4 3.3s-.5 2.4-1.4 3.3c-3 3.1-3 8.3.2 11.6zM62.6 70.3h.4c2.6-.6 5.2-.9 8-.9 1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8c-2.9 0-5.9.4-8.8 1-1 .2-1.6 1.2-1.3 2.1.2.9.9 1.4 1.7 1.4zM34.7 100h.4c.9 0 1.6-.6 1.8-1.5 1.8-10.2 8-19.3 16.5-24.4.9-.5 1.2-1.6.6-2.5s-1.6-1.2-2.5-.6C42 76.6 35.2 86.6 33.2 97.9c-.2 1.1.5 1.9 1.5 2.1z"/><path pid="1" d="M117.2 97.6C113.9 78.5 98.9 63.2 80 59.5c0-.3.1-.6.1-.9 0-5-4.1-9-9-9s-9 4.1-9 9c0 .3.1.6.1.9-19.1 3.7-33.9 19-37.2 38.1-3.8 2.1-7.9 5.2-7.9 8.8 0 9.1 27.9 15.3 54.1 15.3s54.1-6.1 54.1-15.3c-.2-3.5-4.3-6.6-8.1-8.8zM65.6 58.9v-.4c0-3 2.4-5.4 5.4-5.4s5.4 2.4 5.4 5.4v.4h-.3c-.6-.1-1.2-.1-1.9-.2-.3 0-.4 0-.7-.1-.9-.1-1.7-.1-2.6-.1s-1.7 0-2.6.1c-.3 0-.4 0-.7.1-.6 0-1.2.1-1.9.2h-.1zm-1.1 3.8c1-.2 1.9-.3 2.9-.4.2 0 .4 0 .6-.1 1.9-.1 4-.1 5.9 0 .2 0 .4 0 .6.1 1 .1 1.9.2 2.9.4 18.8 2.8 33.6 17.8 36.3 36.6.1.6.2 1.2.2 1.8 0 .2.1.4.1.6.1.8.1 1.6.2 2.4-28.3 6.6-58.1 6.6-86.4 0 0-.8.1-1.6.2-2.4 0-.2 0-.4.1-.6.1-.6.1-1.2.2-1.8 2.6-18.8 17.4-33.8 36.2-36.6zm6.5 55.4c-30.8 0-50.4-7.7-50.4-11.7 0-.7.9-2.2 3.7-4.2v.3c-.1 1-.1 1.9-.1 3v1.4l1.4.4c14.9 3.5 30.1 5.3 45.5 5.3s30.6-1.8 45.5-5.3l1.4-.4v-1.4c0-1 0-2-.1-3v-.3c2.8 1.9 3.7 3.5 3.7 4.2-.2 4-19.8 11.7-50.6 11.7z"/></g>'
  }
})
